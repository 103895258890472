<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 22C5.2 22 4.729 21.8043 4.337 21.413C3.94567 21.021 3.75 20.55 3.75 20V6C3.75 5.45 3.94567 4.979 4.337 4.587C4.729 4.19567 5.2 4 5.75 4H9.95C10.1667 3.4 10.5293 2.91667 11.038 2.55C11.546 2.18333 12.1167 2 12.75 2C13.3833 2 13.9583 2.18333 14.475 2.55C14.9917 2.91667 15.35 3.4 15.55 4H19.75C20.3 4 20.771 4.19567 21.163 4.587C21.5543 4.979 21.75 5.45 21.75 6V20C21.75 20.55 21.5543 21.021 21.163 21.413C20.771 21.8043 20.3 22 19.75 22H5.75ZM5.75 20H19.75V6H17.75V7C17.75 7.55 17.5543 8.02067 17.163 8.412C16.771 8.804 16.3 9 15.75 9H9.75C9.2 9 8.72933 8.804 8.338 8.412C7.946 8.02067 7.75 7.55 7.75 7V6H5.75V20ZM12.75 6C13.0333 6 13.271 5.904 13.463 5.712C13.6543 5.52067 13.75 5.28333 13.75 5C13.75 4.71667 13.6543 4.479 13.463 4.287C13.271 4.09567 13.0333 4 12.75 4C12.4667 4 12.2293 4.09567 12.038 4.287C11.846 4.479 11.75 4.71667 11.75 5C11.75 5.28333 11.846 5.52067 12.038 5.712C12.2293 5.904 12.4667 6 12.75 6Z"
      fill="#365FA3"
    />
  </svg>
</template>
